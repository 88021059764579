<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'

export default {
  name: 'menu',
  data() {
    return {
      ...api.command.getState(),
      sysTypeCode: [],
      searchData: {
        sysCode: 'FutureRural'
      }
    }
  },
  mounted() {
    this.getInitData()

    getAction('/api/app/list').then(result => {
      this.sysTypeCode = result.data.map(e => {
        return {
          ...e,
          text: e.appName,
          name: e.appName,
          value: e.appCode
        }
      })
    })
  },

  methods: {
    getInitData() {
      api.command.getList.call(this, {
        url: `/menu/tree`,
        isPage: false,
        params: {
          sysCode: this.searchData.sysCode
        }
      })
    },
    getHeader() {
      return [
        {
          name: '所属系统',
          type: 'select',
          key: 'sysCode',
          typeData: this.sysTypeCode,
          onChange: val => {
            this.searchData.sysCode = val
          }
        },
        {
          label: '',
          type: 'text',
          key: ''
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-90',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '菜单名称',
          filter: true,
          align: 'left',
          width: 80,
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'display',
          title: '是否显示',
          type: 'badge',
          align: 'center',
          width: 80,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '是' : '否',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        // {
        //   dataIndex: 'sysCode',
        //   title: '所属系统',
        //   width: 60,
        //   align: 'left'
        // },
        {
          dataIndex: 'icon',
          title: '图标',
          width: 50,
          align: 'left'
          //   type: 'image'
        },
        {
          dataIndex: 'type',
          title: '菜单类型',
          width: 30,
          align: 'center',
          customRender: function(text, records) {
            return <div>{text == '0' ? '菜单' : '按钮'}</div>
          }
        },
        {
          dataIndex: 'title',
          title: '菜单标题',
          width: 50,
          align: 'center'
        },
        {
          dataIndex: 'sort',
          title: '排序',
          width: 60,
          align: 'center'
        },
        {
          dataIndex: 'permission',
          title: '权限标识',
          width: 60,
          align: 'left',
          align: 'center'
        },
        {
          dataIndex: 'path',
          title: '主键路径',
          width: 60,
          isId: true,
          align: 'center'
        },
        // {
        //   dataIndex: 'createTime',
        //   title: '创建时间',
        //   type: 'lt-100',
        //   sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix()
        // },
        // {
        //   dataIndex: 'status',
        //   title: '状态',
        //   width: 80,
        //   type: 'badge',
        //   onExport: records => {
        //     return ['正常', '失效'][records]
        //   },
        //   filters: [
        //     {
        //       text: '失效',
        //       value: '1'
        //     },
        //     {
        //       text: '正常',
        //       value: '0'
        //     }
        //   ],
        //   onFilter: (value, record) => record.internal == value,
        //   render(data) {
        //     return {
        //       showDot: true,
        //       name: data == 0 ? '正常' : '失效',
        //       color: data == 0 ? 'red' : 'green'
        //     }
        //   }
        // },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              //   {
              //     name: records.status == 0 ? '失效' : '正常',
              //     onClick: () => {
              //       api.command.edit
              //         .call(this, {
              //           url: `/menu/${
              //             records.status == 0 ? 'disable' : 'enable'
              //           }?id=${records.id}`,
              //           isPost: false
              //         })
              //         .then(result => {
              //           this.getInitData()
              //         })
              //     }
              //   },
              {
                name: '添加子菜单',
                onClick: () =>
                  this.$router.push(
                    `/system/menuDetail?id=${records.id}&isAddChildren=${0}&sysCode=${this.searchData.sysCode}`
                  )
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/system/menuDetail?id=${records.id}&isAddChildren=${1}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/menu/${records.id}`,
                    isPost: false
                  })
                  setTimeout(() => {
                    this.getInitData(this.searchData.sysCode)
                  }, 800)
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/system/menuDetail')
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //
        //       }
        //     }
        //   ]
        // }
      ]
    },
    onSearch(value) {
      this.searchData = {
        ...value
      }
      this.getInitData(value.sysCode)
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        onSearch={this.onSearch}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        showPagination={false}
        filterRightButtonKey={['setting']}
      />
    )
  }
}
</script>

<style scoped lang="less">
tr {
  td {
    &:nth-of-type(1) {
      /deep/&.ant-table-column-has-sorters {
        display: flex;
        align-items: center;
        height: 57px;
      }
    }
  }
}
</style>
